<template>
  <section>
    <b-container fluid>
      <b-row v-if="!ihaleYok">
        <b-col xl="4" lg="12" sm="12" md="12" cols="12">
          <b-card-actions
              v-if="ihaleBilgileri"
              id="ihale-detay-bilgi-card"
              ref="first-card"
              @refresh="refreshStop('first-card')"
              class="mt-1"
          >
            <b-row class="mt-2">
              <b-col cols="12">
                <b-badge variant="light-success">
                  {{ ihaleBilgileri.ihaleDurum == 1 ? 'Firma Teklifi Onaylandı' : 'Devam Ediyor' }}
                </b-badge>
                <b-dropdown
                    v-if="ihaleBilgileri.ihaleDurum == 1"
                    text="Fatura İşlemleri"
                    variant="outline-success"
                    class="fatura-dropdown"
                >
                  <b-dropdown-item v-if="ihaleBilgileri.kayitID != '0' && ihaleBilgileri.kayitID !== null"
                                   @click="$router.push({path:`/faturaDetay/${ihaleBilgileri.kayitID}`})"
                                   variant="success"
                  >
                    <font-awesome-icon icon="star" class="text-warning"/>
                    Faturayı Görüntüle
                  </b-dropdown-item>
                  <b-dropdown-item v-if="ihaleBilgileri.kayitID === '0' || ihaleBilgileri.kayitID === null"
                                   @click="createFatura()"
                  >Fatura Oluştur
                  </b-dropdown-item>
                  <b-dropdown-item v-if="ihaleBilgileri.kayitID === '0' || ihaleBilgileri.kayitID === null"
                                   @click="getAlisFaturalar()"
                                   v-b-toggle.alis-faturalari
                  >
                    İlişkilendir
                  </b-dropdown-item>
                  <b-dropdown-item v-if="ihaleBilgileri.kayitID != '0' && ihaleBilgileri.kayitID !== null"
                                   @click="faturaIlisikKes()"
                  >
                    Fatura İle İlişiğini Kes
                  </b-dropdown-item>
                </b-dropdown>
              </b-col>
              <b-col cols="12" class="text-right mt-2">
                <b-badge v-if="ihaleBilgileri.kayitID != '0' && ihaleBilgileri.kayitID !== null" variant="light-primary"
                         class="cursor-pointer"
                         @click="$router.push({path:`/faturaDetay/${ihaleBilgileri.kayitID}`})"
                >
                  Fatura Mevcut
                </b-badge>
              </b-col>
            </b-row>
            <b-row class="text-center">
              <b-col cols="12">
                <font-awesome-icon icon="fa-solid fa-gavel" class="fa-2xl" style="width: 80px; height: 80px" :style="{
                 color: ihaleBilgileri.kayitID != '0' && ihaleBilgileri.kayitID !== null ? 'rgb(253 216 93)' : '',opacity: ihaleBilgileri.kayitID != '0' && ihaleBilgileri.kayitID !== null ? '0.9':'1'}"
                />
                <h3 class="mt-3">
                  {{ ihaleBilgileri.ihaleBaslik }}
                </h3>
                <b-badge variant="light-secondary">
                  Proje No : {{ ihaleDetay.projeNo }}
                </b-badge>
                <b-row>
                  <b-col>
                    <div class="info-container">
                      <ul class="list-unstyled text-left">
                        <li class="mb-75" id="butonlar">
                          <h4 class="d-inline-block font-weight-bold me-25">İhale Detayları</h4>
                          <div class="d-inline-block float-right">
                            <b-button
                                variant="success"
                                class="btn-icon rounded-circle mr-50"
                                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                                size="sm"
                                v-b-tooltip.hover.top="'İhaleyi Düzenle'"
                                :style="{backgroundColor:$store.getters.COLORS_GET.bgSuccess+'!important', border:'none'}"
                                @click="isEdit = true"
                            >
                              <font-awesome-icon icon="fa-solid fa-user-pen"/>
                            </b-button>
                            <b-button
                                variant="danger"
                                class="btn-icon rounded-circle"
                                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                                size="sm"
                                v-b-tooltip.hover.top="'İhaleyi Sil'"
                                @click="ihaleRemove"
                                :style="{backgroundColor:$store.getters.COLORS_GET.bgDanger+'!important', border:'none'}"
                            >
                              <font-awesome-icon icon="fa-solid fa-trash-can"/>
                            </b-button>
                          </div>
                        </li>
                        <hr/>
                        <li class="mb-75">
                          <span class="font-weight-bold me-25">Belge Tarih Sayı : </span>
                          <span>{{ ihaleBilgileri.belgeTarihSayi }}</span>
                        </li>
                        <li class="mb-75">
                          <span class="font-weight-bold me-25">Belge Tarih : </span>
                          <span>{{ ihaleBilgileri.belgeTarih }}</span>
                        </li>
                        <li class="mb-75">
                          <span class="font-weight-bold me-25">İhale Yetkilisi : </span>
                          <span>{{ ihaleBilgileri.ihaleYetkilisi }}</span>
                        </li>
                        <li class="mb-75">
                          <span class="font-weight-bold me-25">İş Tanım : </span>
                          <span>{{ ihaleDetay.isTanim }}</span>
                        </li>
                        <li class="mb-75">
                          <span class="font-weight-bold me-25">İş Nitelik : </span>
                          <span>{{ ihaleDetay.isNitelik }}</span>
                        </li>
                        <li class="mb-75">
                          <span class="font-weight-bold me-25">Miktar : </span>
                          <span>{{ ihaleDetay.isMiktar }}</span>
                        </li>
                        <li class="mb-75">
                          <span class="font-weight-bold me-25">İlan Şekli Adeti : </span>
                          <span>{{ ihaleDetay.ilanSekliAdeti }}</span>
                        </li>
                        <li class="mb-75">
                          <span class="font-weight-bold me-25">İhale Usulü : </span>
                          <span>{{ ihaleDetay.ihaleUsulu }}</span>
                        </li>
                        <li class="mb-75">
                          <span class="font-weight-bold me-25">Bütçe Tertibi : </span>
                          <span>{{ ihaleDetay.butceTertibi }}</span>
                        </li>
                        <li class="mb-75">
                          <span class="font-weight-bold me-25">Fiyat Fark Dayanağı : </span>
                          <span>{{ ihaleDetay.fiyatFarkDayanagi }}</span>
                        </li>
                        <li class="mb-75">
                          <span class="font-weight-bold me-25">Döküman Satış Bedeli : </span>
                          <span>{{ ihaleDetay.dokumanSatisBedeli }}</span>
                        </li>
                        <li class="mb-75">
                          <span class="font-weight-bold me-25">Bakanlar Kurulu Kararı : </span>
                          <span>{{ ihaleDetay.bakanlarKuruluKarari }}</span>
                        </li>
                        <li class="mb-75">
                          <span class="font-weight-bold me-25">Avans Şartları : </span>
                          <span>{{ ihaleDetay.avansSartlari }}</span>
                        </li>
                        <li class="mb-75">
                          <span class="font-weight-bold me-25">Diğer Açıklamalar : </span>
                          <span>{{ ihaleDetay.digerAciklamalar }}</span>
                        </li>
                      </ul>
                      <hr/>
                      <b-row v-if="false">
                        <b-col class="media-responsive">
                          <b-media no-body>
                            <b-media-aside
                                class="media-aside-p"
                            >
                              <b-avatar
                                  size="48"
                                  variant="light-primary"
                                  class="pull-up"
                              >
                                <font-awesome-icon icon="fa-solid fa-receipt" class="fa-2xl"/>
                              </b-avatar>
                            </b-media-aside>
                            <b-media-body class="my-auto">
                              <h4 class="font-weight-bolder mb-0 cari-ozet-money">
                                {{
                                  Number(cariOzet.alisFaturalar)
                                }}
                                Tl
                              </h4>
                              <b-card-text class="font-small-3 mb-0">
                                Alış Faturaları
                              </b-card-text>
                            </b-media-body>
                          </b-media>
                        </b-col>
                        <b-col class="media-responsive">
                          <b-media no-body>
                            <b-media-aside
                                class="media-aside-p"
                            >
                              <b-avatar
                                  size="48"
                                  variant="light-danger"
                                  class="pull-up"
                              >
                                <font-awesome-icon icon="fa-solid fa-receipt" class="fa-2xl"/>
                              </b-avatar>
                            </b-media-aside>
                            <b-media-body class="my-auto">
                              <h4 class="font-weight-bolder mb-0 cari-ozet-money">
                                {{
                                  Number(cariOzet.satisFaturalar)
                                }}
                                TL
                              </h4>
                              <b-card-text class="font-small-3 mb-0">
                                Satış Faturaları
                              </b-card-text>
                            </b-media-body>
                          </b-media>
                        </b-col>
                        <b-col class="media-responsive">
                          <b-media no-body id="cari-media">
                            <b-media-aside
                                class="media-aside-p"
                            >
                              <b-avatar
                                  size="48"
                                  :variant="cariOzet.satislar - cariOzet.alislar < 0 ? 'light-warning':'light-success'"
                                  class="pull-up"
                              >
                                <font-awesome-icon icon="fa-solid fa-turkish-lira-sign" class="fa-2xl"/>
                              </b-avatar>
                            </b-media-aside>
                            <b-media-body class="my-auto">
                              <h4 class="font-weight-bolder mb-0 cari-ozet-money">
                                {{
                                  Number(cariOzet.bakiye)
                                }}
                                TL
                              </h4>
                              <b-card-text class="font-small-3 mb-0">
                                Bakiye
                              </b-card-text>
                            </b-media-body>
                          </b-media>
                        </b-col>
                      </b-row>
                    </div>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </b-card-actions>
        </b-col>
        <b-col xl="8" lg="12" sm="12" md="12" cols="12">
          <b-card-actions
              v-if="getKomisyonUyeleri && !isEdit"
              id="ihale-detay-bilgi-card"
              ref="first-card"
              @refresh="refreshStop('second-card')"
              class="mt-1"
          >
            <b-row class="mt-50">
              <div class="card-header-row" :style="{backgroundColor: $store.getters.COLORS_GET.cardTitle}">
                <b-col cols="12">
                  <h4 class="text-white d-inline-block card-title">İhale İşlemleri</h4>
                  <b-button
                      v-if="firmalar.length > 0"
                      v-b-modal.firma-kayit
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="primary"
                      :style="{backgroundColor:$store.getters.COLORS_GET.bgInfo+'!important'}"
                      class="float-right ihale-detay-header-btn border-0"
                  >
                    Firma Kayıt
                  </b-button>
                  <b-button
                      v-if="firmalar.length > 0"
                      v-b-modal.teklif-olustur
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="primary"
                      :style="{backgroundColor:$store.getters.COLORS_GET.bgSuccess+'!important'}"
                      class="float-right mr-50 ihale-detay-header-btn border-0"
                  >
                    Teklif Oluştur
                  </b-button>
                </b-col>
              </div>
            </b-row>
            <b-tabs class="mt-5">
              <b-tab
                  active
                  title="Firmalar"
              >
                <b-card-actions
                    id="firma-card"
                    ref="firma-card"
                    @refresh="refreshStop('firma-card')"
                    class="mt-1"
                >
                  <b-row v-if="firmalar.length === 0">
                    <b-col lg="12" md="12" sm="12" cols="12" class="mt-2">
                      <b-button
                          v-b-modal.teklif-olustur
                          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                          variant="primary"
                          class="mr-2"
                      >
                        Teklif Oluştur
                      </b-button>
                      <b-button
                          v-b-modal.firma-kayit
                          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                          variant="primary"
                          :class="width < 430 ? 'mt-50':''"
                      >
                        Firma Kayıt
                      </b-button>
                    </b-col>
                  </b-row>
                  <h5 v-if="firmalar.length !== 0" class="mt-1">Teklif Veren Firmalar</h5>
                  <hr v-if="firmalar.length !== 0" class="m-0"/>
                  <b-tabs>
                    <b-tab
                        :active="i === 0"
                        v-for="(item,i) in firmalar" :key="item.atamaID"
                        :title="item.firmaBilgileri.firmaAdi !== ' ' ? item.firmaBilgileri.firmaAdi : item.firmaBilgileri.firmaUnvan"
                    >
                      <table class="table table-striped">
                        <thead>
                        <tr>
                          <th scope="col"
                              :style="{backgroundColor: $store.getters.COLORS_GET.tableTitle +'!important',color:'white!important'}"
                          >
                            Kalem Adı
                          </th>
                          <th scope="col"
                              :style="{backgroundColor: $store.getters.COLORS_GET.tableTitle +'!important',color:'white!important'}"
                          >Miktar
                          </th>
                          <th scope="col"
                              :style="{backgroundColor: $store.getters.COLORS_GET.tableTitle +'!important',color:'white!important'}"
                          >Birim Türü
                          </th>
                          <th scope="col"
                              :style="{backgroundColor: $store.getters.COLORS_GET.tableTitle +'!important',color:'white!important'}"
                          >Birim Fiyat
                          </th>
                          <th scope="col"
                              :style="{backgroundColor: $store.getters.COLORS_GET.tableTitle +'!important',color:'white!important'}"
                          >Birim KDV
                          </th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="kalem in item.teklifler" :key="kalem.kalemID">
                          <td class="p-0">{{ kalem.kalemAdi }}</td>
                          <td class="p-0">{{ kalem.miktar }}</td>
                          <td class="p-0">{{ kalem.birimTuru }}</td>
                          <td class="p-0"> {{
                              Number(kalem.birimFiyat)
                            }}
                            TL
                          </td>
                          <td class="p-0">{{ kalem.birimKDV }}%</td>
                        </tr>
                        </tbody>
                      </table>
                      <b-button
                          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                          variant="danger"
                          size="sm"
                          class="border-0 float-right mt-1 mb-1"
                          @click="teklifSil(item.atamaID)"
                          :disabled="ihaleBilgileri.firmaID == item.firmaID"
                      >
                        <font-awesome-icon icon="fa-solid fa-trash-can" class="mr-50"/>
                        <span class="align-middle"
                        >{{ ihaleBilgileri.firmaID == item.firmaID ? 'Teklif Silinemez' : 'Teklifi Sil' }}</span>
                      </b-button>
                      <b-button
                          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                          variant="success"
                          size="sm"
                          class="border-0 float-right mt-1 mb-1 mr-50"
                          @click="kabulEt(item.firmaID)"
                          v-if="ihaleBilgileri.firmaID != item.firmaID"
                      >
                        <font-awesome-icon icon="fa-solid fa-check" class="mr-50"/>
                        <span class="align-middle">Teklifi Kabul Et</span>
                      </b-button>
                      <b-button
                          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                          variant="warning"
                          size="sm"
                          class="border-0 float-right mt-1 mb-1 mr-1"
                          v-b-modal.teklif-olustur
                          @click="teklifDuzenleAtama(item)"
                      >
                        <font-awesome-icon icon="fa-solid fa-trash-can" class="mr-50"/>
                        <span class="align-middle"
                        >Teklifi Düzenle</span>
                      </b-button>
                      <b-button
                          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                          variant="danger"
                          size="sm"
                          class="border-0 float-right mt-1 mb-1 mr-50"
                          v-if="ihaleBilgileri.firmaID == item.firmaID"
                          @click="teklifIptal()"
                      >
                        <font-awesome-icon icon="fa-solid fa-check" class="mr-50"/>
                        <span class="align-middle">Teklifi İptal Et</span>
                      </b-button>
                    </b-tab>
                  </b-tabs>
                </b-card-actions>
              </b-tab>
              <b-tab
                  title="Komisyon Üyeleri"
              >
                <b-row>
                  <b-col lg="8" md="12" xs="12" cols="12">
                    <table class="table table-striped komisyon-table" :class="width  < 430 ? 'table-responsive':''">
                      <thead>
                      <tr>
                        <th scope="col"
                            :style="{backgroundColor: $store.getters.COLORS_GET.tableTitle +'!important',color:'white!important'}"
                        >Sıra No
                        </th>
                        <th scope="col"
                            :style="{backgroundColor: $store.getters.COLORS_GET.tableTitle +'!important',color:'white!important'}"
                        >Ad Soyad
                        </th>
                        <th scope="col"
                            :style="{backgroundColor: $store.getters.COLORS_GET.tableTitle +'!important',color:'white!important'}"
                        >Görev
                        </th>
                        <th scope="col"
                            :style="{backgroundColor: $store.getters.COLORS_GET.tableTitle +'!important',color:'white!important'}"
                        >Ünvan
                        </th>
                        <th scope="col"
                            :style="{backgroundColor: $store.getters.COLORS_GET.tableTitle +'!important',color:'white!important'}"
                        />
                      </tr>
                      </thead>
                      <tbody>
                      <tr v-for="(item,index) in evrakGet" :key="index">
                        <td scope="row">{{ item.siraNo }}</td>
                        <td>{{ item.uye.adSoyad }}</td>
                        <td>{{ item.gorev }}</td>
                        <td>{{ item.unvan }}</td>
                        <td>
                          <!--                          <b-button-->
                          <!--                            variant="gradient-danger"-->
                          <!--                            class="btn-icon rounded-circle mr-50"-->
                          <!--                            size="sm"-->
                          <!--                            @click="komisyonRemove(index)"-->
                          <!--                            :style="{backgroundColor:$store.getters.COLORS_GET.bgDanger+'!important',border:'none'}"-->
                          <!--                          >-->
                          <!--                            <font-awesome-icon icon="fa-solid fa-trash-can"/>-->
                          <!--                          </b-button>-->
                        </td>
                      </tr>
                      </tbody>
                    </table>
                    <h5 class="text-center" v-if="evrakGet.length === 0">Üye Eklenmedi</h5>
                  </b-col>
                  <b-col lg="4" md="12" xs="12" cols="12">
                    <b-list-group class="cursor-pointer" :class="width < 992 ? 'mt-2':''">
                      <b-list-group-item @click="komisyonPanel(1)"
                                         class="d-flex justify-content-between align-items-center text-white evrak-list-border"
                                         :style="{backgroundColor:  currentEvrak === 1 ? '#6096B4' : komisyonUyeleri['evrak1'].length === 0 ? '#F48484':'#62B6B7'}"
                      >
                        <span>Evrak No: 1  /  Malzeme Talep</span>
                        <b-badge
                            v-if="currentEvrak === 1"
                            variant="primary"
                            class="badge-round"
                        >
                          ✔
                        </b-badge>
                      </b-list-group-item>
                      <b-list-group-item @click="komisyonPanel(2)"
                                         class="d-flex justify-content-between align-items-center text-white evrak-list-border"
                                         :style="{backgroundColor:  currentEvrak === 2 ? '#6096B4' : komisyonUyeleri['evrak2'].length === 0 ? '#F48484':'#62B6B7'}"
                      >
                        <span>Evrak No: 2  /  Satın Alma</span>
                        <b-badge
                            v-if="currentEvrak === 2"
                            variant="primary"
                            class="badge-round"
                        >
                          ✔
                        </b-badge>
                      </b-list-group-item>
                      <b-list-group-item @click="komisyonPanel(3)"
                                         class="d-flex justify-content-between align-items-center text-white evrak-list-border"
                                         :style="{backgroundColor:  currentEvrak === 3 ? '#6096B4' : komisyonUyeleri['evrak3'].length === 0 ? '#F48484':'#62B6B7'}"
                      >
                        <span>Evrak No: 3  /  Fiyat Araştırma</span>
                        <b-badge
                            v-if="currentEvrak === 3"
                            variant="primary"
                            class="badge-round"
                        >
                          ✔
                        </b-badge>
                      </b-list-group-item>
                      <b-list-group-item @click="komisyonPanel(4)"
                                         class="d-flex justify-content-between align-items-center text-white evrak-list-border"
                                         :style="{backgroundColor:  currentEvrak === 4 ? '#6096B4' : komisyonUyeleri['evrak4'].length === 0 ? '#F48484':'#62B6B7'}"
                      >
                        <span>Evrak No: 4  /  Yaklaşık Maliyet Hesaplama Tablosu</span>
                        <b-badge
                            v-if="currentEvrak === 4"
                            variant="primary"
                            class="badge-round"
                        >
                          ✔
                        </b-badge>
                      </b-list-group-item>
                      <b-list-group-item @click="komisyonPanel(5)"
                                         class="d-flex justify-content-between align-items-center text-white evrak-list-border"
                                         :style="{backgroundColor:  currentEvrak === 5 ? '#6096B4' : komisyonUyeleri['evrak5'].length === 0 ? '#F48484':'#62B6B7'}"
                      >
                        <span>Evrak No: 5  /  İhale Onay</span>
                        <b-badge
                            v-if="currentEvrak === 5"
                            variant="primary"
                            class="badge-round"
                        >
                          ✔
                        </b-badge>
                      </b-list-group-item>
                      <b-list-group-item @click="komisyonPanel(6)"
                                         class="d-flex justify-content-between align-items-center text-white evrak-list-border"
                                         :style="{backgroundColor:  currentEvrak === 6 ? '#6096B4' : komisyonUyeleri['evrak6'].length === 0 ? '#F48484':'#62B6B7'}"
                      >
                        <span>Evrak No: 6  /  Birim Fiyat Teklif Mektubu</span>
                        <b-badge
                            v-if="currentEvrak === 6"
                            variant="primary"
                            class="badge-round"
                        >
                          ✔
                        </b-badge>
                      </b-list-group-item>
                      <b-list-group-item @click="komisyonPanel(7)"
                                         class="d-flex justify-content-between align-items-center text-white evrak-list-border"
                                         :style="{backgroundColor:  currentEvrak === 7 ? '#6096B4' : komisyonUyeleri['evrak7'].length === 0 ? '#F48484':'#62B6B7'}"
                      >
                        <span>Evrak No: 7  /  Teklif Cetveli</span>
                        <b-badge
                            v-if="currentEvrak === 7"
                            variant="primary"
                            class="badge-round"
                        >
                          ✔
                        </b-badge>
                      </b-list-group-item>
                      <b-list-group-item @click="komisyonPanel(8)"
                                         class="d-flex justify-content-between align-items-center text-white evrak-list-border"
                                         :style="{backgroundColor:  currentEvrak === 8 ? '#6096B4' : komisyonUyeleri['evrak8'].length === 0 ? '#F48484':'#62B6B7'}"
                      >
                        <span>Evrak No: 8  /  Yaklaşık Maliyet Hesaplama Cetveli</span>
                        <b-badge
                            v-if="currentEvrak === 8"
                            variant="primary"
                            class="badge-round"
                        >
                          ✔
                        </b-badge>
                      </b-list-group-item>
                      <b-list-group-item @click="komisyonPanel(9)"
                                         class="d-flex justify-content-between align-items-center text-white evrak-list-border"
                                         :style="{backgroundColor:  currentEvrak === 9 ? '#6096B4' : komisyonUyeleri['evrak9'].length === 0 ? '#F48484':'#62B6B7'}"
                      >
                        <span>Evrak No: 9  /  İhaleye Katılanları Belirleme</span>
                        <b-badge
                            v-if="currentEvrak === 9"
                            variant="primary"
                            class="badge-round"
                        >
                          ✔
                        </b-badge>
                      </b-list-group-item>
                      <b-list-group-item @click="komisyonPanel(10)"
                                         class="d-flex justify-content-between align-items-center text-white evrak-list-border"
                                         :style="{backgroundColor:  currentEvrak === 10 ? '#6096B4' : komisyonUyeleri['evrak10'].length === 0 ? '#F48484':'#62B6B7'}"
                      >
                        <span>Evrak No: 10  /  Karar Tutanağı</span>
                        <b-badge
                            v-if="currentEvrak === 10"
                            variant="primary"
                            class="badge-round"
                        >
                          ✔
                        </b-badge>
                      </b-list-group-item>
                      <b-list-group-item @click="komisyonPanel(11)"
                                         class="d-flex justify-content-between align-items-center text-white evrak-list-border"
                                         :style="{backgroundColor:  currentEvrak === 11 ? '#6096B4' : komisyonUyeleri['evrak11'].length === 0 ? '#F48484':'#62B6B7'}"
                      >
                        <span>Evrak No: 11  /  Teslim Alma Tutanağı</span>
                        <b-badge
                            v-if="currentEvrak === 11"
                            variant="primary"
                            class="badge-round"
                        >
                          ✔
                        </b-badge>
                      </b-list-group-item>
                    </b-list-group>
                  </b-col>
                </b-row>
              </b-tab>
              <b-tab
                  title="Kalemler"
              >
                <b-card-actions
                    id="kalem-card"
                    ref="kalem-card"
                    class="mt-1"
                    @refresh="refreshStop('kalem-card')"
                >
                  <table class="table table-striped kalem-table" :class="width < 370 ? 'table-responsive':''">
                    <thead>
                    <tr>
                      <th scope="col"
                          :style="{backgroundColor: $store.getters.COLORS_GET.tableTitle +'!important',color:'white!important'}"
                      >Kalem Adı
                      </th>
                      <th scope="col"
                          :style="{backgroundColor: $store.getters.COLORS_GET.tableTitle +'!important',color:'white!important'}"
                      >Miktar
                      </th>
                      <th scope="col"
                          :style="{backgroundColor: $store.getters.COLORS_GET.tableTitle +'!important',color:'white!important'}"
                      >Birim Türü
                      </th>
                      <th scope="col"
                          :style="{backgroundColor: $store.getters.COLORS_GET.tableTitle +'!important',color:'white!important'}"
                      >Asgari Özellikler
                      </th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="kalem in kalemler" :key="kalem.kalemID">
                      <td class="p-0">{{ kalem.kalemAdi }}</td>
                      <td class="p-0">{{ kalem.miktar }}</td>
                      <td class="p-0">{{ kalem.birimTuru }}</td>
                      <td class="p-0">{{ kalem.asgariOzellik }}</td>
                    </tr>
                    </tbody>
                  </table>
                </b-card-actions>
              </b-tab>
              <b-tab
                  title="Dosyalar"
              >
                <b-row>
                  <b-col xl="4" lg="6" sm="12" cols="12">
                    <b-card-actions
                        ref="evrak1"
                        @refresh="refreshStop('evrak1')"
                        class="mt-1 word"
                        v-b-modal.evrak1
                    >
                      <h5> 1 - Malzeme Talep Formu </h5>
                    </b-card-actions>
                  </b-col>
                  <b-col xl="4" lg="6" sm="12" cols="12">
                    <b-card-actions
                        ref="evrak2"
                        @refresh="refreshStop('evrak2')"
                        class="mt-1 word"
                        v-b-modal.evrak2
                    >
                      <h5> 2 - Satın Alma Komisyon Üyeleri </h5>
                    </b-card-actions>
                  </b-col>
                  <b-col xl="4" lg="6" sm="12" cols="12">
                    <b-card-actions
                        ref="evrak3"
                        @refresh="refreshStop('evrak3')"
                        class="mt-1 word"
                        v-b-modal.evrak3
                    >
                      <h5> 3 - Fiyat Araştırma Tespit Tutanağı </h5>
                    </b-card-actions>
                  </b-col>
                  <b-col xl="4" lg="6" sm="12" cols="12">
                    <b-card-actions
                        ref="evrak4"
                        @refresh="refreshStop('evrak4')"
                        class="mt-1 word"
                        v-b-modal.evrak4
                        :style="{opacity: !firmalar.length > 0 ?'0.5':'1',cursor: !firmalar.length > 0 ?'default':'pointer'}"
                    >
                      <h5> 4 - YAKLAŞIK MALİYET HESAPLAMA TABLOSU </h5>
                    </b-card-actions>
                  </b-col>
                  <b-col xl="4" lg="6" sm="12" cols="12">
                    <b-card-actions
                        ref="evrak5"
                        @refresh="refreshStop('evrak5')"
                        class="mt-1 word"
                        v-b-modal.evrak5
                    >
                      <h5> 5 - İhale Onay Belgesi </h5>
                    </b-card-actions>
                  </b-col>
                  <b-col xl="4" lg="6" sm="12" cols="12">
                    <b-card-actions
                        ref="evrak6"
                        @refresh="refreshStop('evrak6')"
                        class="mt-1 word"
                        v-b-modal.evrak6
                    >
                      <h5> 6 - Birim Fiyat Teklifi Mektubu </h5>
                    </b-card-actions>
                  </b-col>
                  <b-col xl="4" lg="6" sm="12" cols="12">
                    <b-card-actions
                        ref="evrak7"
                        @refresh="refreshStop('evrak7')"
                        class="mt-1 word"
                        v-b-modal.evrak7
                    >
                      <h5> 7 - Birim Fiyat Teklif Cetveli </h5>
                    </b-card-actions>
                  </b-col>
                  <b-col xl="4" lg="6" sm="12" cols="12">
                    <b-card-actions
                        ref="evrak8"
                        @refresh="refreshStop('evrak8')"
                        class="mt-1 word"
                        v-b-modal.evrak8
                        :style="{opacity: !firmalar.length > 0 ?'0.5':'1',cursor: !firmalar.length > 0 ?'default':'pointer'}"
                    >
                      <h5> 8 - Yaklaşık Maliyet Hesap Cetveli </h5>
                    </b-card-actions>
                  </b-col>
                  <b-col xl="4" lg="6" sm="12" cols="12">
                    <b-card-actions
                        ref="evrak9"
                        @refresh="refreshStop('evrak9')"
                        class="mt-1 word"
                        v-b-modal.evrak9
                        :style="{opacity: !firmalar.length > 0 ?'0.5':'1',cursor: !firmalar.length > 0 ?'default':'pointer'}"
                    >
                      <h5> 9 - İhaleye Katılanları Belirleme</h5>
                    </b-card-actions>
                  </b-col>
                  <b-col xl="4" lg="6" sm="12" cols="12">
                    <b-card-actions
                        ref="evrak10"
                        @refresh="refreshStop('evrak10')"
                        class="mt-1 word"
                        v-b-modal.evrak10
                        :style="{opacity: ihaleBilgileri.firmaID == 0 ?'0.5':'1',cursor: ihaleBilgileri.firmaID == 0 ?'default':'pointer'}"
                    >
                      <h5> 10 - Karar Tutanağı</h5>
                    </b-card-actions>
                  </b-col>
                  <b-col xl="4" lg="6" sm="12" cols="12">
                    <b-card-actions
                        ref="evrak11"
                        @refresh="refreshStop('evrak11')"
                        class="mt-1 word"
                        v-b-modal.evrak11
                        :style="{opacity: ihaleBilgileri.firmaID == 0 ?'0.5':'1',cursor: ihaleBilgileri.firmaID == 0 ?'default':'pointer'}"
                    >
                      <h5> Doğrudan Temin Sözleşmesi</h5>
                    </b-card-actions>
                  </b-col>
                  <b-col xl="4" lg="6" sm="12" cols="12">
                    <b-card-actions
                        ref="evrak12"
                        @refresh="refreshStop('evrak12')"
                        class="mt-1 word"
                        v-b-modal.evrak12
                        :style="{opacity: ihaleBilgileri.firmaID == 0 ?'0.5':'1',cursor: ihaleBilgileri.firmaID == 0 ?'default':'pointer'}"
                    >
                      <h5>Teslim Alma Formu</h5>
                    </b-card-actions>
                  </b-col>
                </b-row>
              </b-tab>
            </b-tabs>
          </b-card-actions>
          <b-card-actions
              v-if="getKomisyonUyeleri && isEdit"
              id="ihale-detay-bilgi-card"
              ref="third-card"
              @refresh="refreshStop('third-card')"
              class="mt-1"
          >
            <b-row class="mt-50">
              <div class="card-header-row" :style="{backgroundColor: $store.getters.COLORS_GET.bgWarning}">
                <b-col cols="12">
                  <h4 class="text-white d-inline-block ihale-edit-title">İhale Düzenleniyor</h4>
                  <b-button
                      variant="flat-primary"
                      class="text-white float-right bg-info bg-lighten-1 border-0"
                      :style="{backgroundColor:$store.getters.COLORS_GET.bgDanger + '!important'}"
                      @click="isEdit = false"
                  >
                    Vazgeç
                  </b-button>
                  <b-button
                      variant="flat-primary"
                      class="text-white float-right bg-info bg-lighten-1 mr-1 border-0"
                      :style="{backgroundColor:$store.getters.COLORS_GET.bgSuccess + '!important'}"
                      @click="update"
                  >
                    Değişiklikleri Kaydet
                  </b-button>
                </b-col>
              </div>
            </b-row>
            <hr class="mt-5"/>
            <b-row>
              <b-col
                  md="3"
                  xl="3"
              >
                <b-form-group
                    label="İhale Başlık"
                    label-for="basicInput"
                >
                  <b-form-input
                      id="basicInput"
                      autocomplete="off"
                      v-model="editItem.ihaleBaslik"
                  />
                </b-form-group>
              </b-col>
              <b-col
                  md="3"
                  xl="3"
              >
                <b-form-group
                    label="Belge Tarih Sayı"
                    label-for="basicInput"
                >
                  <cleave
                      id="delimiter"
                      class="form-control"
                      v-model="editItem.belgeTarihSayi"
                      :raw="false"
                      :options="delimiter"
                      autocomplete="off"
                  />
                </b-form-group>
              </b-col>
              <b-col md="3" xl="3">
                <b-form-group
                    label="Belge Tarih"
                    label-for="basicInput"
                >
                  <flat-pickr
                      v-model="editItem.belgeTarih"
                      class="form-control"
                  />
                </b-form-group>
              </b-col>
              <b-col
                  md="3"
                  xl="3"
              >
                <b-form-group
                    label="Alım Yapılacak Birim"
                    label-for="basicInput"
                >
                  <b-form-input
                      id="basicInput"
                      autocomplete="off"
                      v-model="editItem.ilgiliBirim"
                  />
                </b-form-group>
              </b-col>
              <b-col
                  md="3"
                  xl="3"
              >
                <b-form-group
                    label="Proje No"
                    label-for="basicInput"
                >
                  <b-form-input
                      id="basicInput"
                      autocomplete="off"
                      v-model="editItem.projeNo"
                  />
                </b-form-group>
              </b-col>
              <b-col
                  md="3"
                  xl="3"
              >
                <b-form-group
                    label="İş Tanım"
                    label-for="basicInput"
                >
                  <b-form-input
                      id="basicInput"
                      autocomplete="off"
                      v-model="editItem.isTanim"
                  />
                </b-form-group>
              </b-col>
              <b-col
                  md="3"
                  xl="3"
              >
                <b-form-group
                    label="İş Nitelik"
                    label-for="basicInput"
                >
                  <b-form-input
                      id="basicInput"
                      autocomplete="off"
                      v-model="editItem.isNitelik"
                  />
                </b-form-group>
              </b-col>
              <b-col
                  md="3"
                  xl="3"
              >
                <b-form-group
                    label="Miktar"
                    label-for="basicInput"
                >
                  <b-form-input
                      id="basicInput"
                      autocomplete="off"
                      v-model="editItem.isMiktar"
                  />
                </b-form-group>
              </b-col>
              <b-col
                  md="3"
                  xl="3"
              >
                <b-form-group
                    label="İlan Şekli Adeti"
                    label-for="basicInput"
                >
                  <b-form-input
                      id="basicInput"
                      autocomplete="off"
                      v-model="editItem.ilanSekliAdeti"
                  />
                </b-form-group>
              </b-col>
              <b-col
                  md="3"
                  xl="3"
              >
                <b-form-group
                    label="İhale Usulü"
                    label-for="basicInput"
                >
                  <b-form-input
                      id="basicInput"
                      autocomplete="off"
                      v-model="editItem.ihaleUsulu"
                  />
                </b-form-group>
              </b-col>
              <b-col
                  md="3"
                  xl="3"
              >
                <b-form-group
                    label="Bütçe Tertibi"
                    label-for="basicInput"
                >
                  <b-form-input
                      id="basicInput"
                      autocomplete="off"
                      v-model="editItem.butceTertibi"
                  />
                </b-form-group>
              </b-col>
              <b-col
                  md="3"
                  xl="3"
              >
                <b-form-group
                    label="Fiyat Fark Dayanağı"
                    label-for="basicInput"
                >
                  <b-form-input
                      id="basicInput"
                      autocomplete="off"
                      v-model="editItem.fiyatFarkDayanagi"
                  />
                </b-form-group>
              </b-col>
              <b-col
                  md="3"
                  xl="3"
              >
                <b-form-group
                    label="Döküman Satış Bedeli"
                    label-for="basicInput"
                >
                  <b-form-input
                      id="basicInput"
                      autocomplete="off"
                      v-model="editItem.dokumanSatisBedeli"
                  />
                </b-form-group>
              </b-col>
              <b-col
                  md="3"
                  xl="3"
              >
                <b-form-group
                    label="Bakanlar Kurulu Kararı"
                    label-for="basicInput"
                >
                  <b-form-input
                      id="basicInput"
                      autocomplete="off"
                      v-model="editItem.bakanlarKuruluKarari"
                  />
                </b-form-group>
              </b-col>
              <b-col
                  md="3"
                  xl="3"
              >
                <b-form-group
                    label="Avans Şartları"
                    label-for="basicInput"
                >
                  <b-form-textarea v-model="editItem.avansSartlari" rows="3"/>
                </b-form-group>
              </b-col>
              <b-col
                  md="6"
                  xl="6"
              >
                <b-form-group
                    label="Diğer Açıklamalar"
                    label-for="basicInput"
                >
                  <b-form-textarea v-model="editItem.digerAciklamalar" rows="3"/>
                </b-form-group>
              </b-col>
            </b-row>
          </b-card-actions>
        </b-col>
      </b-row>
      <b-row v-if="ihaleYok">
        <b-col cols="12">
          <div class="card bg-primary text-white">
            <div class="card-body">
              <h4 class="card-title text-white">{{ this.id }} nolu id' ye ait İhale Kaydı Bulunamadı.</h4>
              <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="warning"
                  class="mt-2"
                  @click="$router.go(-1)"
              >
                Geri Dön
              </b-button>
            </div>
          </div>
        </b-col>
      </b-row>
    </b-container>
    <b-modal
        id="firma-kayit"
        title="Firma Kayıt"
        centered
        size="lg"
        ok-title="Kaydet"
        cancel-title="Vazgeç"
        @ok="firmaKayit"
        :ok-disabled="!firmaSchema.unvan || !firmaSchema.adi"
    >
      <b-row class="m-1">
        <b-col cols="4">
          <b-form-group
              label="Firma Kısa Unvan*"
              label-for="basicInput"
          >
            <b-form-input
                v-model="firmaSchema.adi"
                id="basicInput"
                autocomplete="off"
            />
          </b-form-group>
        </b-col>
        <b-col cols="5">
          <b-form-group
              label="Firma Tam Unvan*"
              label-for="basicInput"
          >
            <b-form-input
                v-model="firmaSchema.unvan"
                id="basicInput"
                autocomplete="off"
            />
          </b-form-group>
        </b-col>
        <b-col cols="3">
          <b-form-group
              label="Telefon"
              label-for="basicInput"
          >
            <b-form-input
                v-model="firmaSchema.telefon"
                id="basicInput"
                autocomplete="off"
            />
          </b-form-group>
        </b-col>
        <b-col cols="5">
          <b-form-group
              label="Adres"
              label-for="basicInput"
          >
            <b-form-input
                v-model="firmaSchema.adres"
                id="basicInput"
                autocomplete="off"
            />
          </b-form-group>
        </b-col>
        <b-col cols="4">
          <b-form-group
              label="Vergi No"
              label-for="basicInput"
          >
            <b-form-input
                v-model="firmaSchema.vergiNo"
                id="basicInput"
                autocomplete="off"
            />
          </b-form-group>
        </b-col>
        <b-col cols="3">
          <b-form-group
              label="Vergi Dairesi"
              label-for="basicInput"
          >
            <b-form-input
                v-model="firmaSchema.vergiDairesi"
                id="basicInput"
                autocomplete="off"
            />
          </b-form-group>
        </b-col>
      </b-row>
    </b-modal>
    <b-modal
        id="teklif-olustur"
        :title="!isTeklifUpdate ? 'Teklif Oluştur':`${teklifVerenFirma.firmaAdi} Adlı Firmanın Teklifi Düzenleniyor`"
        class="teklif-olustur-modal"
        centered
        size="lg"
        ok-title="Kaydet"
        cancel-title="Vazgeç"
        @ok="!isTeklifUpdate? teklifOlustur() : teklifDuzenle()"
        @hidden="teklifUpdateModalHidden()"
        :ok-disabled="!teklifVerenFirma.firmaID"
        no-close-on-backdrop
    >
      <b-row>
        <b-col cols="12">
          <b-form-group
              label="Firma Adı"
              label-for="basicInput"
          >
            <v-select
                v-model="teklifVerenFirma"
                label="firmaUnvan"
                :options="firmaListesi"
                no-options-text="Seçenek Yok"
            >
              <template #selected-option-container="{ option }">
                <div class="vs__selected">
                  {{ option.firmaUnvan.toUpperCase() }} - {{ option.firmaAdi ? option.firmaAdi : '' }}
                </div>
              </template>
              <template #option="{ firmaUnvan, firmaAdi }">
                {{ firmaUnvan.toUpperCase() }} - {{ firmaAdi ? firmaAdi : '' }}
              </template>
              <template #list-header>
                <li
                    v-b-modal.firma-kayit
                    class="add-new-client-header d-flex align-items-center my-50"
                >
                  <feather-icon
                      icon="PlusIcon"
                      size="16"
                      class="ml-50"
                  />
                  <span class="align-middle ml-25">Firma Ekle</span>
                </li>
              </template>
              <template #no-options>
                <span>Sonuç Bulunamadı</span>
              </template>
            </v-select>
          </b-form-group>
        </b-col>
      </b-row>
      <table class="table table-striped table-hover">
        <thead>
        <tr>
          <th class="p-0" scope="col">Kalem</th>
          <th class="p-0" scope="col">Birim Fiyat</th>
          <th class="p-0" scope="col">Birim KDV (%)</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(item ,i) in teklifKalemleri" :key="i">
          <td class="p-0">
            <b-form-input
                id="smallInput"
                size="sm"
                class="teklif-kalem-input"
                autocomplete="off"
                disabled
                v-model="item.kalemAdi"
            />
          </td>
          <td class="p-0">
            <b-form-input
                id="smallInput"
                size="sm"
                autocomplete="off"
                class="teklif-fiyat-input"
                type="number"
                v-model="item.birimFiyat"
            />
          </td>
          <td class="p-0">
            <b-form-input
                id="smallInput"
                size="sm"
                autocomplete="off"
                type="number"
                class="teklif-kdv-input"
                v-model="item.kdv"
            />
          </td>
        </tr>
        </tbody>
      </table>
    </b-modal>
    <b-sidebar
        id="alis-faturalari"
        shadow
        bg-variant="white"
        backdrop
        width="500px"
    >
      <template #header>
        <b-row class="w-100">
          <b-col cols="12">
            <p class="font-weight-bold mt-1 text-center">Alış Faturaları</p>
          </b-col>
          <b-col cols="12">
            <hr>
          </b-col>
        </b-row>
      </template>
      <b-form-group
          label-for="basicInput"
          class="mx-5"
      >
        <b-form-input
            id="basicInput"
            v-model="faturaAra"
            autocomplete="off"
            placeholder="Fatura Ara"
            @input="getAlisFaturalar"
        />
      </b-form-group>
      <b-list-group class="mx-1 mt-2">
        <small class="mb-1 font-weight-bold">Max 20 kayıt</small>
        <b-list-group-item v-for="item in alisFaturaList" :key="item.kayitID" class="d-flex justify-content-between">
          <div class="w-100">
            <span class="mr-1">
              <font-awesome-icon icon="file-invoice" class="fa-xl"/>
            </span>
            <p class="font-weight-bold d-inline-block mb-50">{{
                item.faturaUnvan ? item.faturaUnvan : item.adi + ' ' + item.soyAdi
              }}</p>
            <b-badge size="sm" class="mr-1 float-right" :variant="item.durum === '0' ?'light-warning':'light-primary'">
              {{ item.durum === '0' ? 'Açık Fatura' : 'Kapalı Fatura' }}
            </b-badge>
            <p class="mb-0 mr-1">{{ item.aciklama }}</p>
            <p class="mb-0 mr-1"> Toplam Tutar : {{
                Number(item.toplamTutar)
                    .toFixed(2)
              }} TL</p>
            <p class="mb-0 mr-1"> Düzenlenme Tarihi : {{ item.duzenlenmeTarih }}</p>
          </div>
          <div>
            <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                size="sm"
                variant="outline-success"
                @click="faturaIliskilendir(item.kayitID)"
            >
              İlişkilendir
            </b-button>
            <b-button
                v-b-modal.show-fatura
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                size="sm"
                class="d-block mt-50"
                variant="outline-primary"
                @click="faturaPreview = item"
            >
              Görüntüle
            </b-button>
            <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                size="sm"
                class="d-block mt-50"
                variant="outline-primary"
                @click="$router.push({ name: 'faturaDetay', params: { id: item.kayitID } })"
            >
              Faturaya Git
            </b-button>
          </div>
        </b-list-group-item>
      </b-list-group>
    </b-sidebar>
    <b-modal
        id="show-fatura"
        centered
        title="Fatura Önizleme"
        size="lg"
        ok-title="İlişkilendir"
        cancel-title="Kapat"
        cancel-variant="secondary"
        ok-variant="success"
        @ok="faturaIliskilendir(faturaPreview.kayitID)"
    >
      <b-row class="fatura-preview">
        <b-col cols="12" class="mb-1">
          <b-badge size="sm" class="mr-1 float-right"
                   variant="light-primary"
          >
            Alış Faturası
          </b-badge>
          <b-badge size="sm" class="mr-1 float-right"
                   :variant="faturaPreview.durum === '0' ?'light-warning':'light-primary'"
          >
            {{ faturaPreview.durum === '0' ? 'Açık Fatura' : 'Kapalı Fatura' }}
          </b-badge>
        </b-col>
        <b-col lg="6" md="6" sm="12" cols="6">
          <b-list-group flush>
            <b-list-group-item class="text-center font-weight-bolder">Cari Bilgileri</b-list-group-item>
            <b-list-group-item class="font-weight-bold">Fatura Unvan : {{
                faturaPreview.faturaUnvan
              }}
            </b-list-group-item>
            <b-list-group-item class="font-weight-bold">Tel : {{ faturaPreview.telefon }}</b-list-group-item>
            <b-list-group-item class="font-weight-bold">E-Posta : {{ faturaPreview.ePosta }}</b-list-group-item>
            <b-list-group-item class="font-weight-bold">Vergi Dairesi : {{
                faturaPreview.vergiDairesi
              }}
            </b-list-group-item>
            <b-list-group-item class="font-weight-bold">Vergi Numarası : {{
                faturaPreview.vergiNumarasi
              }}
            </b-list-group-item>
          </b-list-group>
        </b-col>
        <b-col lg="6" md="6" sm="12" cols="6">
          <b-list-group flush>
            <b-list-group-item class="text-center font-weight-bolder">Fatura Bilgileri</b-list-group-item>
            <b-list-group-item class="font-weight-bold">Toplam Tutar : {{
                Number(faturaPreview.toplamTutar)
                    .toFixed(2)
              }} TL
            </b-list-group-item>
            <b-list-group-item class="font-weight-bold">Tahsilat Tarihi : {{ faturaPreview.tahsilatTarih }}
            </b-list-group-item>
            <b-list-group-item class="font-weight-bold">Düzenlenme Tarihi : {{ faturaPreview.duzenlenmeTarih }}
            </b-list-group-item>
            <b-list-group-item class="font-weight-bold">Fatura Notu : {{ faturaPreview.faturaNotu }}
            </b-list-group-item>
            <b-list-group-item class="font-weight-bold">Açıklama : {{ faturaPreview.aciklama }}
            </b-list-group-item>
          </b-list-group>
        </b-col>
      </b-row>
    </b-modal>
    <dosyalar-modal v-if="ihaleDetay" :bilgiler="ihaleBilgileri" :detay="ihaleDetay" :uyeler="evrakUyeleri"
                    :kalemler="kalemler" :teklifler="firmalar"
    />
  </section>
</template>
<script>
import {
  BSidebar, VBToggle,
  VBModal,
  BAvatar,
  BBadge,
  BButton,
  BCard,
  BCardHeader,
  BCardText,
  BCol,
  BCollapse,
  BContainer, BDropdown, BDropdownItem,
  BForm,
  BFormCheckbox,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BFormSelect,
  BFormTextarea, BImg,
  BInputGroup,
  BInputGroupPrepend,
  BListGroup,
  BListGroupItem,
  BMedia,
  BMediaAside,
  BMediaBody,
  BPagination,
  BRow,
  BTab,
  BTable,
  BTabs,
  VBTooltip,
} from 'bootstrap-vue'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import store from '@/store'
import router from '@/router'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import _ from 'lodash'
import axios from 'axios'
import dosyalarModal from './dosyalar/dosyalarModal.vue'
import Cleave from 'vue-cleave-component'
import { useWindowSize } from '@vueuse/core'

const Turkish = require('flatpickr/dist/l10n/tr.js').default.tr
flatpickr.localize(Turkish)
flatpickr(flatPickr)
export default {
  setup() {
    const {
      width,
    } = useWindowSize()
    return {
      width,
    }
  },
  components: {
    BSidebar,
    Cleave,
    dosyalarModal,
    BCollapse,
    BDropdownItem,
    BDropdown,
    BImg,
    BForm,
    vSelect,
    BFormInvalidFeedback,
    BPagination,
    BInputGroupPrepend,
    BTab,
    BTabs,
    BMedia,
    BMediaAside,
    BMediaBody,
    BAvatar,
    BFormTextarea,
    flatPickr,
    BCard,
    BCardText,
    BContainer,
    BFormSelect,
    BBadge,
    BTable,
    BListGroup,
    BListGroupItem,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BInputGroup,
    BButton,
    BRow,
    BCol,
    BCardHeader,
    BCardActions,
  },
  data() {
    return {
      faturaAra: null,
      isTeklifUpdate: false,
      faturaPreview: [],
      alisFaturaList: [],
      degismeyenDataBilgi: [],
      degismeyenDataDetay: [],
      delimiter: {
        delimiter: '/',
        blocks: [4, 2],
        uppercase: true,
      },
      evrakUyeleri: [],
      teklifKalemleri: [],
      teklifKalemleriOld: [],
      updateTeklifData: {
        firmaID: '',
        ihaleID: '',
      },
      teklifVerenFirma: '',
      firmaSchema: {
        adi: '',
        unvan: '',
        adres: '',
        telefon: '',
        vergiNo: '',
        vergiDairesi: '',
      },
      ihaleYok: false,
      isEdit: false,
      editItem: {
        ilgiliBirim: '',
        projeNo: '',
        ihaleBaslik: '',
        belgeTarih: '',
        belgeTarihSayi: '',
        isTanim: '',
        isNitelik: '',
        isMiktar: '',
        ilanSekliAdeti: '',
        ihaleUsulu: '',
        butceTertibi: '',
        fiyatFarkDayanagi: '',
        dokumanSatisBedeli: '',
        bakanlarKuruluKarari: '',
        avansSartlari: '',
        digerAciklamalar: '',
      },
      id: this.$route.params.id,
      komisyonUyeleri: {
        evrak1: [],
        evrak2: [],
        evrak3: [],
        evrak4: [],
        evrak5: [],
        evrak6: [],
        evrak7: [],
        evrak8: [],
        evrak9: [],
        evrak10: [],
        evrak11: [],
      },
      currentEvrak: 1,
      uyeEkle: {
        siraNo: 1,
        uye: '',
        gorev: '',
      },
      gorevOpt: ['Başkan', 'Başkan Yrd.', 'Üye'],
    }
  },
  computed: {
    firmaListesi() {
      if (store.getters.FIRMA_LISTESI_GET) {
        return Object.values(store.getters.FIRMA_LISTESI_GET)
      }
      return []
    },
    kalemler() {
      if (store.getters.IHALE_DETAY_GET.ihaleKalemleri) {
        return Object.values(store.getters.IHALE_DETAY_GET.ihaleKalemleri)
      }
      return []
    },
    firmalar() {
      if (store.getters.IHALE_DETAY_GET.ihaleFirmalari) {
        return Object.values(store.getters.IHALE_DETAY_GET.ihaleFirmalari)
      }
      return []
    },
    getKomisyonUyeleri() {
      if (store.getters.IHALE_DETAY_GET.komisyonUyeleri) {
        return Object.values(store.getters.IHALE_DETAY_GET.komisyonUyeleri)
      }
      return []
    },
    ihaleDetay() {
      return store.getters.IHALE_DETAY_GET.ihaleDetay
    },
    ihaleBilgileri() {
      if (store.getters.IHALE_DETAY_GET.ihaleBilgileri) {
        return store.getters.IHALE_DETAY_GET.ihaleBilgileri
      }
      return false
    },
    evrakGet() {
      return _.orderBy(this.komisyonUyeleri[`evrak${this.currentEvrak}`], 'siraNo', 'asc')
    },
  },
  methods: {
    faturaIlisikKes() {
      const fd = new FormData()
      fd.append('crm_token', store.getters.TOKEN_GET)
      fd.append('serviceName', 'ihale')
      fd.append('methodName', 'ihaleIlisikKes')
      fd.append('ihaleID', this.id)
      axios.post(store.state.POST_URL, fd, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
          .then((res, position = 'bottom-right') => {
            if (res.data.result.status === 200) {
              const mesaj = store.getters.notificationSettings(res.data)
              if (mesaj) {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Başarılı!',
                    text: mesaj,
                    icon: 'ThumbsUpIcon',
                    variant: 'success',
                  },
                }, position)
              }
              this.createdPost()
            } else {
              const mesaj = store.getters.notificationSettings(res.data)
              if (mesaj) {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Hata!',
                    text: mesaj,
                    icon: 'ThumbsDownIcon',
                    variant: 'danger',
                  },
                })
              }
            }
          })
          .catch(err => {
            const mesaj = store.getters.notificationSettings(err.data)
            if (mesaj) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Hata!',
                  text: mesaj,
                  icon: 'ThumbsDownIcon',
                  variant: 'danger',
                },
              })
            }
          })
    },
    createFatura() {
      localStorage.setItem('associateID', this.id)
      this.$router.push({ path: '/yeniAlis' })
    },
    faturaIliskilendir(kayitID) {
      if (kayitID) {
        const fd = new FormData()
        fd.append('crm_token', store.getters.TOKEN_GET)
        fd.append('serviceName', 'ihale')
        fd.append('methodName', 'ihaleIliskilendir')
        fd.append('kayitID', kayitID)
        fd.append('ihaleID', this.id)
        axios.post(store.state.POST_URL, fd, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
            .then((res, position = 'bottom-right') => {
              if (res.data.result.status === 200) {
                const mesaj = store.getters.notificationSettings(res.data)
                if (mesaj) {
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: 'Başarılı!',
                      text: mesaj,
                      icon: 'ThumbsUpIcon',
                      variant: 'success',
                    },
                  }, position)
                }
                this.createdPost()
                this.$bvModal.hide('show-fatura')
                this.$root.$emit('bv::toggle::collapse', 'alis-faturalari')
              } else {
                const mesaj = store.getters.notificationSettings(res.data)
                if (mesaj) {
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: 'Hata!',
                      text: mesaj,
                      icon: 'ThumbsDownIcon',
                      variant: 'danger',
                    },
                  })
                }
              }
            })
            .catch(err => {
              const mesaj = store.getters.notificationSettings(err.data)
              if (mesaj) {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Hata!',
                    text: mesaj,
                    icon: 'ThumbsDownIcon',
                    variant: 'danger',
                  },
                })
              }
            })
      }
    },
    getAlisFaturalar(filter) {
      const fd = new FormData()
      fd.append('crm_token', store.getters.TOKEN_GET)
      fd.append('serviceName', 'fatura')
      fd.append('methodName', 'faturaListesiSearch')
      fd.append('baslangic', 0)
      fd.append('uzunluk', 20)
      fd.append('faturaTuru', 2)
      if (filter) {
        fd.append('searchKey', filter)
      }
      axios.post(store.state.POST_URL, fd, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
          .then(res => this.alisFaturaList = res.data.data)
    },
    teklifIptal() {
      if (this.ihaleBilgileri.kayitID === '0' || this.ihaleBilgileri.kayitID === null) {
        this.$swal({
          title: 'Teklifi İptal Et',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'İptal Et',
          cancelButtonText: 'Vazgeç',
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1',
          },
          buttonsStyling: false,
        })
            .then(result => {
              if (result.value) {
                store.dispatch('teklifIptal', this.id)
                    .then((res, position = 'bottom-right') => {
                      if (res.result.status == 200) {
                        store.dispatch('ihaleDetay', this.id)
                        const mesaj = store.getters.notificationSettings(res)
                        if (mesaj) {
                          this.$toast({
                            component: ToastificationContent,
                            props: {
                              title: 'Başarılı!',
                              text: mesaj,
                              icon: 'ThumbsDownIcon',
                              variant: 'success',
                            },
                          }, position)
                        }
                      } else {
                        const mesaj = store.getters.notificationSettings(res)
                        if (mesaj) {
                          this.$toast({
                            component: ToastificationContent,
                            props: {
                              title: 'Hata!',
                              text: mesaj,
                              icon: 'ThumbsDownIcon',
                              variant: 'danger',
                            },
                          })
                        }
                      }
                    })
                    .catch(err => {
                      const mesaj = store.getters.notificationSettings(err)
                      if (mesaj) {
                        this.$toast({
                          component: ToastificationContent,
                          props: {
                            title: 'Hata!',
                            text: mesaj,
                            icon: 'ThumbsDownIcon',
                            variant: 'danger',
                          },
                        })
                      }
                    })
              }
            })
      } else {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Hata!',
            text: 'Mevcut Fatura varken, teklif İptal edilemez!',
            icon: 'ThumbsDownIcon',
            variant: 'danger',
          },
        })
      }
    },
    teklifUpdateModalHidden() {
      this.isTeklifUpdate = false
      this.updateTeklifData = {
        firmaID: '',
        ihaleID: '',
      }
      this.teklifVerenFirma = ''
      this.teklifKalemleri = this.teklifKalemleriOld
    },
    teklifDuzenleAtama(teklif) {
      this.isTeklifUpdate = true
      this.updateTeklifData.ihaleID = teklif.ihaleID
      this.updateTeklifData.firmaID = teklif.firmaID
      this.teklifVerenFirma = teklif.firmaBilgileri
      this.teklifKalemleriOld = this.teklifKalemleri
      // this.teklifKalemleri = teklif.teklifler
      this.teklifKalemleri = []
      teklif.teklifler.forEach(item => {
        this.teklifKalemleri.push({
          kalemAdi: item.kalemAdi,
          kalemID: item.kalemID,
          kdv: item.birimKDV,
          miktar: item.miktar,
          birimFiyat: item.birimFiyat,
          teklifID: item.teklifID,
        })
      })
    },
    teklifDuzenle() {
      axios.post(store.state.POST_URL, this.teklifSchema(), {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
          .then((res, position = 'bottom-right') => {
            if (res.data.result.status === 200) {
              this.teklifVerenFirma = ''
              store.dispatch('ihaleDetay', this.id)
                  .then(res => {
                    this.teklifKalemleri = []
                    res.ihaleKalemleri.forEach(item => {
                      this.teklifKalemleri.push({
                        kalemAdi: item.kalemAdi,
                        kalemID: item.kalemID,
                        kdv: item.kdv,
                        miktar: item.miktar,
                        birimFiyat: '',
                      })
                    })
                  })
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Başarılı',
                  text: JSON.stringify(res.data.result.errMsg)
                      .slice(1, -1),
                  icon: 'ThumbsUpIcon',
                  variant: 'success',
                },
              }, { position })
              store.commit('setToken', res.data.userToken)
            } else {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Hata',
                  text: JSON.stringify(res.data.result.errMsg)
                      .slice(1, -1),
                  icon: 'ThumbsDownIcon',
                  variant: 'warning',
                },
              })
            }
          })
          .catch(err => {
            const mesaj = this.$store.getters.notificationSettings(err.data)
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Hata!',
                text: mesaj,
                icon: 'ThumbsDownIcon',
                variant: 'danger',
              },
            })
          })
    },
    kabulEt(firmaID) {
      this.$swal({
        title: 'Teklifi Kabul Et',
        text: this.ihaleBilgileri.firmaID != 0 ? `İhale Onayı ${this.firmalar.find(x => x.firmaID == this.ihaleBilgileri.firmaID)?.firmaBilgileri.firmaUnvan} adlı firmadan,  ${this.firmalar.find(x => x.firmaID == firmaID)?.firmaBilgileri.firmaUnvan} adlı Firmaya geçecek` : '',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Onayla',
        cancelButtonText: 'Vazgeç',
        customClass: {
          confirmButton: 'btn btn-success border-0',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      })
          .then(result => {
            if (result.value) {
              store.dispatch('teklifKabulEt', {
                ihaleID: this.id,
                firmaID,
              })
                  .then((res, position = 'bottom-right') => {
                    if (res.result.status == 200) {
                      store.dispatch('ihaleDetay', this.id)
                      const mesaj = store.getters.notificationSettings(res)
                      if (mesaj) {
                        this.$toast({
                          component: ToastificationContent,
                          props: {
                            title: 'Başarılı!',
                            text: mesaj,
                            icon: 'ThumbsDownIcon',
                            variant: 'success',
                          },
                        }, position)
                      }
                    } else {
                      const mesaj = store.getters.notificationSettings(res)
                      if (mesaj) {
                        this.$toast({
                          component: ToastificationContent,
                          props: {
                            title: 'Hata!',
                            text: mesaj,
                            icon: 'ThumbsDownIcon',
                            variant: 'danger',
                          },
                        })
                      }
                    }
                  })
                  .catch(err => {
                    const mesaj = store.getters.notificationSettings(err)
                    if (mesaj) {
                      this.$toast({
                        component: ToastificationContent,
                        props: {
                          title: 'Hata!',
                          text: mesaj,
                          icon: 'ThumbsDownIcon',
                          variant: 'danger',
                        },
                      })
                    }
                  })
            }
          })
    },
    teklifSil(atamaID) {
      this.$swal({
        title: 'Teklifi Sil',
        text: 'Bu işlem geri alınamaz!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Evet Sil',
        cancelButtonText: 'Vazgeç',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      })
          .then(result => {
            if (result.value) {
              store.dispatch('teklifSil', atamaID)
                  .then((res, position = 'bottom-right') => {
                    if (res.result.status === 200) {
                      store.dispatch('ihaleDetay', this.id)
                      const mesaj = store.getters.notificationSettings(res)
                      if (mesaj) {
                        this.$toast({
                          component: ToastificationContent,
                          props: {
                            title: 'Başarılı!',
                            text: mesaj,
                            icon: 'ThumbsDownIcon',
                            variant: 'success',
                          },
                        }, position)
                      }
                    } else {
                      const mesaj = store.getters.notificationSettings(res)
                      if (mesaj) {
                        this.$toast({
                          component: ToastificationContent,
                          props: {
                            title: 'Hata!',
                            text: mesaj,
                            icon: 'ThumbsDownIcon',
                            variant: 'danger',
                          },
                        })
                      }
                    }
                  })
                  .catch(err => {
                    const mesaj = store.getters.notificationSettings(err)
                    if (mesaj) {
                      this.$toast({
                        component: ToastificationContent,
                        props: {
                          title: 'Hata!',
                          text: mesaj,
                          icon: 'ThumbsDownIcon',
                          variant: 'danger',
                        },
                      })
                    }
                  })
            }
          })
    },
    update() {
      const guncellenenKeylerBilgi = Object.entries(this.degismeyenDataBilgi)
          .reduce((acc, [key, value]) => {
            if (['kayitTarih', 'ihaleID', 'ihaleDurum', 'firmaKodu'].includes(key)) return acc // todo buraya girilen keyler degisse bile algılamayıp pas geçecek
            if (this.editItem[key] !== undefined && this.editItem[key] !== value) {
              acc[key] = this.editItem[key]
            }
            return acc
          }, {})
      const guncellenenKeylerDetay = Object.entries(this.degismeyenDataDetay)
          .reduce((acc, [key, value]) => {
            if (['kayitTarih', 'ihaleID', 'ihaleDurum', 'firmaKodu'].includes(key)) return acc // todo buraya girilen keyler degisse bile algılamayıp pas geçecek
            if (this.editItem[key] !== undefined && this.editItem[key] !== value) {
              acc[key] = this.editItem[key]
            }
            return acc
          }, {})
      const fdBilgi = new FormData()
      const fdDetay = new FormData()
      fdBilgi.append('crm_token', store.state.userToken)
      fdBilgi.append('serviceName', 'ihale')
      fdBilgi.append('methodName', 'ihaleDuzenle')
      fdBilgi.append('ihaleID', this.id)
      fdBilgi.append('duzenle', 'bilgi')
      fdDetay.append('crm_token', store.state.userToken)
      fdDetay.append('serviceName', 'ihale')
      fdDetay.append('methodName', 'ihaleDuzenle')
      fdDetay.append('ihaleID', this.id)
      fdDetay.append('duzenle', 'detay')
      Object.entries(guncellenenKeylerBilgi)
          .forEach(([key, value]) => {
            if (typeof value !== 'boolean') {
              fdBilgi.append(`updateList[${key}]`, value || ' ')
            }
          })
      Object.entries(guncellenenKeylerDetay)
          .forEach(([key, value]) => {
            if (typeof value !== 'boolean') {
              fdDetay.append(`updateListDetay[${key}]`, value || ' ')
            }
          })
      axios.post(store.state.POST_URL, fdBilgi, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
          .then(res => {
            if (res.data.result.status == 200) {
              this.isEdit = false
              store.dispatch('ihaleDetay', this.id)
                  .then(res => {
                    if (res.ihaleDetay) {
                      this.degismeyenDataBilgi = { ...res.ihaleBilgileri }
                      this.degismeyenDataDetay = { ...res.ihaleDetay }
                      this.editItem.belgeTarih = res.ihaleBilgileri.belgeTarih
                      this.editItem.belgeTarihSayi = res.ihaleBilgileri.belgeTarihSayi
                      this.editItem.ihaleBaslik = res.ihaleBilgileri.ihaleBaslik
                      this.editItem.isTanim = res.ihaleDetay.isTanim
                      this.editItem.projeNo = res.ihaleDetay.projeNo
                      this.editItem.isNitelik = res.ihaleDetay.isNitelik
                      this.editItem.isMiktar = res.ihaleDetay.isMiktar
                      this.editItem.ilanSekliAdeti = res.ihaleDetay.ilanSekliAdeti
                      this.editItem.ihaleUsulu = res.ihaleDetay.ihaleUsulu
                      this.editItem.butceTertibi = res.ihaleDetay.butceTertibi
                      this.editItem.fiyatFarkDayanagi = res.ihaleDetay.fiyatFarkDayanagi
                      this.editItem.dokumanSatisBedeli = res.ihaleDetay.dokumanSatisBedeli
                      this.editItem.bakanlarKuruluKarari = res.ihaleDetay.bakanlarKuruluKarari
                      this.editItem.avansSartlari = res.ihaleDetay.avansSartlari
                      this.editItem.digerAciklamalar = res.ihaleDetay.digerAciklamalar
                    }
                  })
              store.commit('setToken', res.data.userToken)
            } else {
              const mesaj = this.$store.getters.notificationSettings(res.data)
              if (mesaj) {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Hata!',
                    text: mesaj,
                    icon: 'ThumbsDownIcon',
                    variant: 'danger',
                  },
                })
              }
            }
          })
          .catch(err => {
            const mesaj = this.$store.getters.notificationSettings(err.data)
            if (mesaj) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Hata!',
                  text: mesaj,
                  icon: 'ThumbsDownIcon',
                  variant: 'danger',
                },
              })
            }
          })
      axios.post(store.state.POST_URL, fdDetay, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
          .then((res, position = 'bottom-right') => {
            if (res.data.result.status == 200) {
              this.isEdit = false
              store.dispatch('ihaleDetay', this.id)
                  .then(res => {
                    if (res.ihaleDetay) {
                      this.degismeyenDataBilgi = { ...res.ihaleBilgileri }
                      this.degismeyenDataDetay = { ...res.ihaleDetay }
                      this.editItem.belgeTarih = res.ihaleBilgileri.belgeTarih
                      this.editItem.belgeTarihSayi = res.ihaleBilgileri.belgeTarihSayi
                      this.editItem.ihaleBaslik = res.ihaleBilgileri.ihaleBaslik
                      this.editItem.isTanim = res.ihaleDetay.isTanim
                      this.editItem.projeNo = res.ihaleDetay.projeNo
                      this.editItem.isNitelik = res.ihaleDetay.isNitelik
                      this.editItem.isMiktar = res.ihaleDetay.isMiktar
                      this.editItem.ilanSekliAdeti = res.ihaleDetay.ilanSekliAdeti
                      this.editItem.ihaleUsulu = res.ihaleDetay.ihaleUsulu
                      this.editItem.butceTertibi = res.ihaleDetay.butceTertibi
                      this.editItem.fiyatFarkDayanagi = res.ihaleDetay.fiyatFarkDayanagi
                      this.editItem.dokumanSatisBedeli = res.ihaleDetay.dokumanSatisBedeli
                      this.editItem.bakanlarKuruluKarari = res.ihaleDetay.bakanlarKuruluKarari
                      this.editItem.avansSartlari = res.ihaleDetay.avansSartlari
                      this.editItem.digerAciklamalar = res.ihaleDetay.digerAciklamalar
                    }
                  })
              store.commit('setToken', res.data.userToken)
              const mesaj = this.$store.getters.notificationSettings(res.data)
              if (mesaj) {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Başarılı',
                    text: mesaj,
                    icon: 'ThumbsUpIcon',
                    variant: 'success',
                  },
                }, { position })
              }
            } else {
              const mesaj = this.$store.getters.notificationSettings(res.data)
              if (mesaj) {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Hata!',
                    text: mesaj,
                    icon: 'ThumbsDownIcon',
                    variant: 'danger',
                  },
                })
              }
            }
          })
          .catch(err => {
            const mesaj = this.$store.getters.notificationSettings(err.data)
            if (mesaj) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Hata!',
                  text: mesaj,
                  icon: 'ThumbsDownIcon',
                  variant: 'danger',
                },
              })
            }
          })
    },
    ihaleRemove() {
      this.$swal({
        title: 'İhaleyi Sil',
        text: 'Bu işlem geri alınamaz!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Evet Sil',
        cancelButtonText: 'Vazgeç',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      })
          .then(result => {
            if (result.value) {
              store.dispatch('ihaleSil', this.id)
                  .then((res, position = 'bottom-right') => {
                    if (res.result.status === 200) {
                      router.push({ name: 'ihaleListesi' })
                      const mesaj = store.getters.notificationSettings(res)
                      if (mesaj) {
                        this.$toast({
                          component: ToastificationContent,
                          props: {
                            title: 'Başarılı!',
                            text: mesaj,
                            icon: 'ThumbsDownIcon',
                            variant: 'success',
                          },
                        }, position)
                      }
                    } else {
                      const mesaj = store.getters.notificationSettings(res)
                      if (mesaj) {
                        this.$toast({
                          component: ToastificationContent,
                          props: {
                            title: 'Hata!',
                            text: mesaj,
                            icon: 'ThumbsDownIcon',
                            variant: 'danger',
                          },
                        })
                      }
                    }
                  })
                  .catch(err => {
                    const mesaj = store.getters.notificationSettings(err)
                    if (mesaj) {
                      this.$toast({
                        component: ToastificationContent,
                        props: {
                          title: 'Hata!',
                          text: mesaj,
                          icon: 'ThumbsDownIcon',
                          variant: 'danger',
                        },
                      })
                    }
                  })
            }
          })
    },
    teklifOlustur() {
      axios.post(store.state.POST_URL, this.teklifSchema(), {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
          .then((res, position = 'bottom-right') => {
            if (res.data.result.status === 200) {
              this.teklifVerenFirma = ''
              store.dispatch('ihaleDetay', this.id)
                  .then(res => {
                    this.teklifKalemleri = []
                    res.ihaleKalemleri.forEach(item => {
                      this.teklifKalemleri.push({
                        kalemAdi: item.kalemAdi,
                        kalemID: item.kalemID,
                        kdv: item.kdv,
                        miktar: item.miktar,
                        birimFiyat: '',
                      })
                    })
                  })
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Başarılı',
                  text: JSON.stringify(res.data.result.errMsg)
                      .slice(1, -1),
                  icon: 'ThumbsUpIcon',
                  variant: 'success',
                },
              }, { position })
              store.commit('setToken', res.data.userToken)
            } else {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Hata',
                  text: JSON.stringify(res.data.result.errMsg)
                      .slice(1, -1),
                  icon: 'ThumbsDownIcon',
                  variant: 'warning',
                },
              })
            }
          })
          .catch(err => {
            const mesaj = this.$store.getters.notificationSettings(err.data)
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Hata!',
                text: mesaj,
                icon: 'ThumbsDownIcon',
                variant: 'danger',
              },
            })
          })
    },
    teklifSchema() {
      const fd = new FormData()
      fd.append('crm_token', store.getters.TOKEN_GET)
      fd.append('serviceName', 'ihale')
      fd.append('methodName', !this.isTeklifUpdate ? 'ihaleTeklifKaydet' : 'ihaleTeklifDuzenle')
      fd.append('ihaleID', this.id)
      fd.append('firmaID', this.teklifVerenFirma.firmaID)
      this.teklifKalemleri.forEach((item, index) => {
        const prefix = `teklifData[${index}]`
        fd.append(`${prefix}[kalemID]`, item.kalemID)
        fd.append(`${prefix}[birimFiyat]`, item.birimFiyat.replace(/,/g, '.') || 0)
        fd.append(`${prefix}[birimKDV]`, item.kdv || 0)
        if(this.isTeklifUpdate) fd.append(`${prefix}[teklifID]`, item.teklifID)
      })
      return fd
    },
    firmaKayit() {
      const fd = new FormData()
      fd.append('crm_token', store.getters.TOKEN_GET)
      fd.append('serviceName', 'ihale')
      fd.append('methodName', 'ihaleFirmaKaydet')
      fd.append('firmaBilgileri[firmaAdi]', this.firmaSchema.adi || ' ')
      fd.append('firmaBilgileri[firmaUnvan]', this.firmaSchema.unvan || ' ')
      fd.append('firmaBilgileri[firmaAdres]', this.firmaSchema.adres || ' ')
      fd.append('firmaBilgileri[firmaTelefon]', this.firmaSchema.telefon || ' ')
      fd.append('firmaBilgileri[firmaVergiNo]', this.firmaSchema.vergiNo || ' ')
      fd.append('firmaBilgileri[firmaVergiDairesi]', this.firmaSchema.vergiDairesi || ' ')
      axios.post(store.state.POST_URL, fd, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
          .then((res, position = 'bottom-right') => {
            if (res.data.result.status == 200) {
              store.dispatch('firmaListesi')
                  .then(res => {
                    const data = { ...res }
                    delete data.userToken
                    this.teklifVerenFirma = Object.values(data)
                        .find(x => x.firmaUnvan == this.firmaSchema.unvan)
                    this.firmaSchema.adi = ''
                    this.firmaSchema.unvan = ''
                    this.firmaSchema.adres = ''
                    this.firmaSchema.telefon = ''
                    this.firmaSchema.vergiNo = ''
                    this.firmaSchema.vergiDairesi = ''
                  })
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Başarılı',
                  text: JSON.stringify(res.data.result.errMsg)
                      .slice(1, -1),
                  icon: 'ThumbsUpIcon',
                  variant: 'success',
                },
              }, { position })
              store.commit('setToken', res.data.userToken)
            } else {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Hata',
                  text: JSON.stringify(res.data.result.errMsg)
                      .slice(1, -1),
                  icon: 'ThumbsDownIcon',
                  variant: 'warning',
                },
              })
            }
          })
          .catch(err => {
            const mesaj = this.$store.getters.notificationSettings(err.data)
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Hata!',
                text: mesaj,
                icon: 'ThumbsDownIcon',
                variant: 'danger',
              },
            })
          })
    },
    komisyonUpdate() {
      const doluMu = Object.keys(this.komisyonUyeleri)
          .every(uye => this.komisyonUyeleri[uye].length > 0)
      if (doluMu) {
      } else {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Hata',
            text: 'Her evrak için en az bir tane üye seçilmelidir',
            icon: 'ThumbsDownIcon',
            variant: 'warning',
          },
        })
      }
      return false
    },
    komisyonRemove(index) {
      const content = `evrak${this.currentEvrak}`
      this.komisyonUyeleri[content].splice(index, 1)
    },
    komisyonUyeEkle() {
      const content = `evrak${this.currentEvrak}`
      const eklenecekEvrak = this.komisyonUyeleri[content]
      if (this.uyeEkle.siraNo === '') {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Hata',
            text: 'Sıra No Girilmelidir',
            icon: 'ThumbsUpIcon',
            variant: 'warning',
          },
        })
        return false
      }
      if (this.uyeEkle.uye === '') {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Hata',
            text: 'Üye Seçilmelidir',
            icon: 'ThumbsUpIcon',
            variant: 'warning',
          },
        })
        return false
      }
      const siraControl = eklenecekEvrak.find(x => x.siraNo === this.uyeEkle.siraNo)
      const uyeControl = eklenecekEvrak.find(x => x.uye.uyeID === this.uyeEkle.uye.uyeID)
      if (!siraControl) {
        if (!uyeControl) {
          eklenecekEvrak.push({
            siraNo: this.uyeEkle.siraNo,
            uye: this.uyeEkle.uye,
            gorev: this.uyeEkle.gorev,
            unvan: this.uyeEkle.uye.unvan,
          })
          this.uyeEkle.siraNo++
          this.uyeEkle.uye = ''
          this.uyeEkle.gorev = ''
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Hata',
              text: 'Aynı Üye 2 Kez Eklenemez',
              icon: 'ThumbsUpIcon',
              variant: 'warning',
            },
          })
        }
      } else {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Hata',
            text: 'Sıra No mevcut',
            icon: 'ThumbsUpIcon',
            variant: 'warning',
          },
        })
      }
      return false
    },
    komisyonPanel(evrakNo) {
      this.currentEvrak = evrakNo
      const content = `evrak${evrakNo}`
      const eklenecekEvrak = this.komisyonUyeleri[content]
      const maxSiraNo = Math.max(...eklenecekEvrak.map(x => x.siraNo), 0)
      this.uyeEkle.siraNo = maxSiraNo + 1
    },
    refreshStop(cardName) {
      setTimeout(() => {
        this.$refs[cardName || 'first-card'].showLoading = false
      }, 1000)
    },
    createdPost() {
      store.dispatch('ihaleDetay', this.id)
          .then(res => {
            if (res.ihaleDetay) {
              this.degismeyenDataBilgi = { ...res.ihaleBilgileri }
              this.degismeyenDataDetay = { ...res.ihaleDetay }
              this.editItem.belgeTarih = res.ihaleBilgileri.belgeTarih
              this.editItem.belgeTarihSayi = res.ihaleBilgileri.belgeTarihSayi
              this.editItem.ihaleBaslik = res.ihaleBilgileri.ihaleBaslik
              this.editItem.ilgiliBirim = res.ihaleBilgileri.ilgiliBirim
              this.editItem.isTanim = res.ihaleDetay.isTanim
              this.editItem.projeNo = res.ihaleDetay.projeNo
              this.editItem.isNitelik = res.ihaleDetay.isNitelik
              this.editItem.isMiktar = res.ihaleDetay.isMiktar
              this.editItem.ilanSekliAdeti = res.ihaleDetay.ilanSekliAdeti
              this.editItem.ihaleUsulu = res.ihaleDetay.ihaleUsulu
              this.editItem.butceTertibi = res.ihaleDetay.butceTertibi
              this.editItem.fiyatFarkDayanagi = res.ihaleDetay.fiyatFarkDayanagi
              this.editItem.dokumanSatisBedeli = res.ihaleDetay.dokumanSatisBedeli
              this.editItem.bakanlarKuruluKarari = res.ihaleDetay.bakanlarKuruluKarari
              this.editItem.avansSartlari = res.ihaleDetay.avansSartlari
              this.editItem.digerAciklamalar = res.ihaleDetay.digerAciklamalar
              res.ihaleKomisyonUyeleri.forEach(item => {
                this.evrakUyeleri.push({
                  adSoyad: item.adSoyad,
                  evrakNo: item.evrakNo,
                  gorev: item.gorev,
                  sira: item.sira,
                  unvan: item.unvan,
                  uyeID: item.uyeID,
                })
              })
              res.ihaleKalemleri.forEach(item => {
                this.teklifKalemleri.push({
                  kalemAdi: item.kalemAdi,
                  kalemID: item.kalemID,
                  kdv: item.kdv,
                  miktar: item.miktar,
                  birimFiyat: '',
                })
              })
              Object.values(res.ihaleKomisyonUyeleri)
                  .forEach(item => {
                    const { evrakNo } = item
                    const obj = {
                      siraNo: item.sira,
                      uye: item,
                      gorev: item.gorev,
                      unvan: item.unvan,
                    }
                    if (evrakNo >= 1 && evrakNo <= 11) {
                      this.komisyonUyeleri[`evrak${evrakNo}`].push(obj)
                    }
                  })
            } else {
              this.ihaleYok = true
            }
          })
    },
  },
  beforeDestroy() {
    store.commit('IHALE_DETAY_ACTION', 'remove')
    store.commit('FIRMA_LISTESI_ACTION', 'remove')
  },
  directives: {
    'b-modal': VBModal,
    'b-toggle': VBToggle,
    'b-tooltip': VBTooltip,
    Ripple,
  },
  created() {
    store.dispatch('firmaListesi')
    this.createdPost()
  },
}
</script>
<style lang="scss">
.kalem-table td {
  padding: 0.32rem 0.2rem;
}

.ihale-detay-header-btn {
  transform: translate(0, 2px);
}

.word .card-body {
  padding: 0;
  margin: 0;
}

.word .card-body {
  .word-box, .pdf-box, .print-box {
    cursor: pointer;
    transition: 800ms;
    padding: 9px 14px;
    border-radius: 12px;
  }

  .word-box {
    background-color: #6DA9E4;
  }

  .pdf-box {
    background-color: #FA9884;
  }

  .print-box {
    background-color: #009FBD;
  }

  svg {
    height: 20px;
    width: 20px;
  }

  .word-box:hover, .pdf-box:hover, .print-box:hover {
    background-color: #0A4D68;
    transform: translateY(-10px);
  }
}

.word .card {
  background-color: #5b8a8d;
}

.word h5 {
  margin: 1rem 0;
  color: white;
  text-align: center;
}

#ihale-detay-bilgi-card .card-header {
  display: none !important;
}

#ihale-detay-bilgi-card .card-header {
  display: none !important;
}

.ihale-edit-title {
  transform: translate(0px, 9px);
}

.komisyon-table td, th {
  padding: 0.3rem !important;
}

#firma-card {
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px !important;
}

.fatura-dropdown {
  float: right;
}

.fatura-dropdown ul li a {
  padding: 0.35rem 1rem 0.35rem 10px !important;
}

.teklif-kalem-input {
  padding: 0.188rem 0.857rem;
  margin: 0.1rem;
  width: 80%;
}

.teklif-fiyat-input {
  padding: 0.188rem 0.857rem;
  margin: 0.1rem;
  width: 70%;
}

.teklif-kdv-input {
  padding: 0.188rem 0.857rem;
  margin: 0.1rem;
  width: 50%;
}

.b-sidebar-header {
  justify-content: center;
}

.fatura-preview .list-group-item {
  padding: 0.20rem 0.25rem !important;
}

@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
